import React from "react";

const Resume = ({ classicHeader, darkTheme }) => {
  const educationDetails = [
    {
      yearRange: "2019 - 2021",
      title: "Bachelor Degree",
      place: "University of California, Davis",
      desc: "I transferred from City College of San Francisco in 2019. I graduated from UC Davis in 2021 with a BS in Computer Science.",
    },
    {
      yearRange: "2016 - 2019",
      title: "Associate Degree",
      place: "City College of San Francisco",
      desc: "After separating from the Air Force, I went back to community college to restart my educational journey.",
    },
  ];

  const experienceDetails = [
    {
      yearRange: "2024 - Present",
      title: "Backend Developer",
      place: "Career Pathway Institute",
      desc: "Developed and updated APIs, updated and maintained the database, implemented sign-in with Google API. Used Python, SQLAlchemy, Postgres, and Docker.",
    },
    {
      yearRange: "2022 - 2023",
      title: "Software Engineer",
      place: "Tabapay",
      desc: "Led maintenance of Tabapay's website and blog. Implemented an Apache dev server for staging. Implemented APIs with Golang and Nodejs",
    },
    {
      yearRange: "2020 - 2021",
      title: "Web Developer",
      place: "UC Davis",
      desc: "Built React and Ruby on Rails tools for professor, which enabled informational research on the efficacy of their lectures and where to improve.",
    },
    {
      yearRange: "2021",
      title: "Software Engineer Intern",
      place: "Intel",
      desc: "Built and maintained APIs using Python, Django, and MySQL.",
    },
  ];

  const skills = [
    {
      name: "Python",
      percent: 80,
    },
    {
      name: "Javascript",
      percent: 90,
    },
    {
      name: "Django",
      percent: 70,
    },
    {
      name: "Express",
      percent: 70,
    },
    {
      name: "Nodejs",
      percent: 70,
    },
    {
      name: "Postgres",
      percent: 75,
    },
  ];

  return (
    <section
      id="resume"
      className={"section " + (darkTheme ? "bg-dark-1" : "")}
    >
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Summary
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {" "}
            Resume
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gx-5">
          {/* My Education */}
          <div className="col-md-6">
            <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              My Education
            </h2>
            {educationDetails.length > 0 &&
              educationDetails.map((value, index) => (
                <div
                  key={index}
                  className={
                    "bg-white  rounded p-4 mb-4 " +
                    (darkTheme ? "bg-dark" : "bg-white border")
                  }
                >
                  <p className="badge bg-primary text-2 fw-400">
                    {value.yearRange}
                  </p>
                  <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                    {value.title}
                  </h3>
                  <p className={darkTheme ? "text-primary" : "text-danger"}>
                    {value.place}
                  </p>
                  <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                    {value.desc}
                  </p>
                </div>
              ))}
          </div>
          {/* My Experience */}
          <div className="col-md-6">
            <h2
              className={
                "text-6 fw-600 mb-4 " + (darkTheme ? "text-white" : "")
              }
            >
              My Experience
            </h2>
            {experienceDetails.length > 0 &&
              experienceDetails.map((value, index) => (
                <div
                  key={index}
                  className={
                    "bg-white  rounded p-4 mb-4 " +
                    (darkTheme ? "bg-dark" : "bg-white border")
                  }
                >
                  <p className="badge bg-primary text-2 fw-400">
                    {value.yearRange}
                  </p>
                  <h3 className={"text-5 " + (darkTheme ? "text-white" : "")}>
                    {value.title}
                  </h3>
                  <p className={darkTheme ? "text-primary" : "text-danger"}>
                    {value.place}
                  </p>
                  <p className={"mb-0 " + (darkTheme ? "text-white-50" : "")}>
                    {value.desc}
                  </p>
                </div>
              ))}
          </div>
        </div>
        {/* My Skills */}
        <h2
          className={
            "text-6 fw-600 mt-4 mb-4 " + (darkTheme ? "text-white" : "")
          }
        >
          My Skills
        </h2>
        <div className="row gx-5">
          {skills.length > 0 &&
            skills.map((skill, index) => (
              <div key={index} className="col-md-6">
                <p
                  className={
                    " fw-500 text-start mb-2 " +
                    (darkTheme ? "text-light" : "text-dark")
                  }
                >
                  {skill.name}{" "}
                  <span className="float-end">{skill.percent}%</span>
                </p>
                <div
                  className={
                    "progress progress-sm mb-4 " + (darkTheme ? "bg-dark" : "")
                  }
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: skill.percent + "%" }}
                    aria-valuenow={skill.percent}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
            ))}
        </div>
        <div className="text-center mt-5">
        </div>
      </div>
    </section>
  );
};

export default Resume;
